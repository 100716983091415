import _ from 'lodash'
import { toRaw } from 'vue'

export const assignIn = (searchData: {}, tableData: {}, pageData: {}) => {
  const t1 = _.assignIn(
    {},
    toRaw(searchData),
    toRaw(tableData),
    toRaw(pageData)
  )
  // const t1 = Object.assign(searchData, tableData, pageData)
  // const t2 = {}
  // for (const key in t1) {
  //   if ((t1 as any)[key] != '') {
  //     ;(t2 as any)[key] = (t1 as any)[key]
  //   }
  // }
  // console.log(t2)
  // return t2
  return _.pickBy(t1, _.identity)
}
