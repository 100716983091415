import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn' // import locale

dayjs.locale('zh-cn') // use locale

export const formatDate = (value: string) => {
  return dayjs(value).format('YYYY-MM-DD')
}

export const formatDateTime = (value: string) => {
  return dayjs(value).format('YYYY-MM-DD hh:mm:ss')
}

export const formatExpiredAt = (value: string) => {
  if (!value) {
    return '无限期'
  } else if (new Date(value).getTime() < new Date().getTime()) {
    return '已过期'
  } else {
    const section = new Date(value).getTime() - new Date().getTime()
    return '剩余' + (section / 86400000).toFixed(0) + '天'
  }
}

export default dayjs
