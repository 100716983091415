
  import { defineComponent } from 'vue'
  import { useVModel } from '@vueuse/core'
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
  import moment from 'moment'
  import 'moment/locale/zh-cn'
  export default defineComponent({
    props: {
      value: Array,
    },
    setup(props, { emit }) {
      const selected = useVModel(props, 'value', emit)
      return {
        selected,
        locale,
      }
    },
  })
