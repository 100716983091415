import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_select = _resolveComponent("my-select")!

  return (_openBlock(), _createBlock(_component_my_select, {
    items: _ctx.items,
    width: _ctx.width,
    placeholder: "状态",
    value: _ctx.selected,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
  }, null, 8, ["items", "width", "value"]))
}