<template>
  <a-select
    show-search
    v-model:value="selected"
    :placeholder="placeholder"
    @search="getSearchFun"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    allowClear
    :disabled="disabled"
    :style="`width:${width};`"
  >
    <a-select-option
      v-for="item in items"
      :key="item.value"
      :value="item.value"
    >
      {{ item.label }}
    </a-select-option>
  </a-select>
</template>
<script>
  import { defineComponent, ref } from 'vue'
  import { API, requests } from '@/api/apis'
  import { useVModel } from '@vueuse/core'
  export default defineComponent({
    props: {
      value: Number,
      disabled: {
        type: Boolean,
        defaule: false,
      },
      placeholder: {
        type: String,
        default: '输入员工名字',
      },
      width: {
        type: String,
        default: '200px',
      },
    },
    setup(props, { emit }) {
      const items = ref([])
      const selected = useVModel(props, 'value', emit)
      let loadstat = false
      const getSearchFun = (key) => {
        if (loadstat) return false
        setTimeout(() => {
          loadstat = false
        }, 1000)
        loadstat = true
        requests({
          url: API.admins.Select,
          data: { keyword: key },
        })
          .then((res) => {
            items.value = res.data
          })
          .catch((err) => {
            console.log(err, 'skk')
          })
      }
      return {
        getSearchFun,
        selected,
        items,
      }
    },
  })
</script>
