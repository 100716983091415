
  import { defineComponent } from 'vue'
  import { useVModel } from '@vueuse/core'
  export default defineComponent({
    props: {
      items: Array,
      value: String,
      placeholder: String,
      allowClear: {
        type: Boolean,
        default: true,
      },
      width: {
        type: String,
        default: '160px',
      },
    },
    setup(props, { emit }) {
      const selected = useVModel(props, 'value', emit)
      return {
        selected,
      }
    },
  })
