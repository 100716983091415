import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.selected,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    placeholder: _ctx.placeholder,
    style: _normalizeStyle(`width:${_ctx.width};`),
    allowClear: _ctx.allowClear,
    options: _ctx.items
  }, null, 8, ["value", "placeholder", "style", "allowClear", "options"]))
}