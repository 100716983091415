<template>
  <a-select
    v-model:value="selected"
    show-search
    :placeholder="placeholder"
    allowClear
    :disabled="disabled"
    :style="`width:${width};`"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    :options="items"
    @search="getSearchFun"
  ></a-select>
</template>
<script>
  import { defineComponent, ref, watchEffect } from 'vue'
  import { API, requests } from '@/api/apis'
  export default defineComponent({
    props: {
      value: Number,
      labelname: String,
      disabled: {
        type: Boolean,
        default: false,
      },
      project: {
        type: Object,
      },
      placeholder: {
        type: String,
        default: '请选择',
      },
      width: {
        type: String,
        default: '200px',
      },
    },
    emits: ['update:value', 'update:labelname'],
    setup(props, ctx) {
      const items = ref([])
      const selected = ref()
      const getSearchFun = (key) => {
        requests({
          url: API.Projects.Select,
          data: { keyword: key },
        })
          .then((res) => {
            items.value = res.data
          })
          .catch((err) => {
            console.log(err, 'skk')
          })
      }
      watchEffect(() => {
        if (selected.value) {
          ctx.emit('update:value', selected.value)
        }

        if (items.value) {
          items.value.forEach((res) => {
            if (res.value == selected.value) {
              ctx.emit('update:labelname', res.label)
            }
          })
        }
      })
      watchEffect(() => {
        if (props.project) {
          getSearchFun(props.project.name)
        }
        if (props.value) {
          selected.value = props.value
        }
      })
      return {
        getSearchFun,
        selected,
        items,
      }
    },
  })
</script>
