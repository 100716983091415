import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!

  return (_openBlock(), _createBlock(_component_a_range_picker, {
    style: {"width":"100%"},
    value: _ctx.selected,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    format: "YYYY/MM/DD",
    locale: _ctx.locale
  }, null, 8, ["value", "locale"]))
}