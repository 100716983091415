
  import { API, requests } from './apis'
  import { defineComponent } from 'vue'
  import { DownOutlined } from '@ant-design/icons-vue'
  export default defineComponent({
    components: { DownOutlined },
    props: {
      data: {
        type: Object,
      },
      pages: {
        type: Object,
      },
    },
    setup(props) {
      const downloadBtn = (data: {}) => {
        requests({
          url: API.Projects.project_applies_dow,
          data: data,
        })
          .then((res) => {
            const disposition = res.headers['content-disposition']
            const blob = new Blob([res.data as any])
            const start = "filename*=utf-8''"
            let fileName = ''
            if (disposition.includes(start)) {
              fileName = disposition.substr(
                disposition.indexOf(start) + start.length
              )
              fileName = decodeURI(fileName)
            }
            if ('download' in document.createElement('a')) {
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href)
              document.body.removeChild(elink)
            } else {
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
      const handleMenuClick = (e: any) => {
        const data: any = props.data
        const pages: any = props.pages
        data.download_type = e.key
        const params = Object.assign(data, pages)
        downloadBtn(params)
      }
      return {
        handleMenuClick,
      }
    },
  })
