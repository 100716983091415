export const searchProps = {
  bordered: false,
  class: 'searchCard',
  form: {
    layout: 'inline',
  },
}

export const tableProps = {
  bordered: false,
  class: 'tableCard',
  table: {
    size: 'small',
    bordered: true,
    pagination: false,
    rowKey: 'id',
  },
}

export const colorProps = (idx: number) => {
  //返回颜色
  const list = ['success', 'processing', 'error', 'warning', 'default']
  return list[idx < 5 ? Math.abs(idx) : Math.floor(Math.random() * 4)]
}

export const getSorter = (sorter: { order: string; field: string }) => {
  return sorter.order == undefined
    ? {}
    : {
        orderBy: [sorter.field, sorter.order],
      }
}
