
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      value: String,
    },
    setup(props) {
      const items = [
        {
          color: 'pink',
          label: '发起人',
          value: '发起人',
        },
        {
          color: 'purple',
          label: '董事',
          value: '董事',
        },
        {
          color: 'blue',
          label: '运营总监',
          value: '运营总监',
        },
        {
          color: 'cyan',
          label: '总经理',
          value: '总经理',
        },
        {
          color: 'orange',
          label: '总监',
          value: '总监',
        },
        {
          color: '#B766AD',
          label: '财务总监',
          value: '财务总监',
        },
      ]

      return {
        items,
        props,
      }
    },
  })
