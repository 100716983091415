
  import MySelect from '@/common/components/Select/index.vue'
  import { defineComponent, ref } from 'vue'
  import { useVModel } from '@vueuse/core'
  export default defineComponent({
    components: { MySelect },
    props: {
      value: String,
      width: String,
    },
    setup(props, { emit }) {
      const selected = useVModel(props, 'value', emit)
      const items = ref([
        { label: '进行中', value: '进行中' },
        { label: '已完成', value: '已完成' },
        { label: '已取消', value: '已取消' },
      ])
      return {
        items,
        selected,
      }
    },
  })
